import { createSlice, createAsyncThunk  } from '@reduxjs/toolkit';
import UserService from "../../services/user-service";

const initialState = {
    loading: false,
    systems: [],
    error: ''
}

export const fetchSystems = createAsyncThunk('system/fetchSystems', async () => {
    const response = await UserService.getUserCompSystems();
    return response.data;
})

const systemSlice = createSlice({
    name: 'system',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchSystems.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchSystems.fulfilled, (state, action) => {
            state.loading = false
            state.systems = action.payload
            state.error = ''
        })
        builder.addCase(fetchSystems.rejected, (state, action) => {
            state.loading = false
            state.systems = []
            state.error = action.error.message
        })
    }
})

export default systemSlice.reducer