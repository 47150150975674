import { createSlice, createAsyncThunk  } from '@reduxjs/toolkit';
import UserService from "../../services/user-service";

const initialState = {
    loading: false,
    allsystems: [],
    error: ''
}

export const fetchAllSystems = createAsyncThunk('allsystems/fetchAllSystems', async () => {
    const response = await UserService.getUserCompUSystems();
    return response.data;
})

const allsystemsSlice = createSlice({
    name: 'allsystems',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchAllSystems.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchAllSystems.fulfilled, (state, action) => {
            state.loading = false
            state.allsystems = action.payload
            state.error = ''
        })
        builder.addCase(fetchAllSystems.rejected, (state, action) => {
            state.loading = false
            state.allsystems = []
            state.error = action.error.message
        })
    }
})

export default allsystemsSlice.reducer