import { createSlice, createAsyncThunk  } from '@reduxjs/toolkit';
import UserService from "../../services/user-service";

const initialState = {
    loading: false,
    companies: [],
    error: ''
}

export const fetchCompanies = createAsyncThunk('company/fetchCompanies', async () => {
    const response = await UserService.getUserComp();
    return response.data;
})

const companySlice = createSlice({
    name: 'company',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchCompanies.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchCompanies.fulfilled, (state, action) => {
            state.loading = false
            state.companies = action.payload
            state.error = ''
        })
        builder.addCase(fetchCompanies.rejected, (state, action) => {
            state.loading = false
            state.companies = []
            state.error = action.error.message
        })
    }
})

export default companySlice.reducer