import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import customerReducer from '../data/customer/CustomerSlice';
import generalReducer from '../data/customer/GeneralSlice';
import custClassReducer from '../data/customer/CustClassification';
import customerByIdReducer from '../data/customer/CustomerByIdSlice';
import userReducer from '../data/user/UserSlice';
import employeeReducer from '../data/customer/EmployeeSlice';
import companyReducer from '../data/customer/CompanySlice';
import systemReducer from '../data/customer/SystemSlice';
import allsystemReducer from '../data/customer/AllSystemsSlice';
import compReducer from '../store/compdetailSlice';

const reducers = combineReducers({
  form: formReducer,
  customer: customerReducer,
  general: generalReducer,
  custClass: custClassReducer,
  singleUser: customerByIdReducer,
  user: userReducer,
  employee: employeeReducer,
  company: companyReducer,
  system: systemReducer,
  allsystems: allsystemReducer,
  comp: compReducer
 
});

export default reducers;
