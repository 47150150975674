import { createSlice, createAsyncThunk  } from '@reduxjs/toolkit';
import UserService from "../../services/user-service";

const initialState = {
    loading: false,
    users: [],
    error: ''
}

export const fetchGeneral = createAsyncThunk('general/fetchGeneral', async () => {
    const response = await UserService.getGeneral();
    return response.data;
})

const generalSlice = createSlice({
    name: 'general',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchGeneral.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchGeneral.fulfilled, (state, action) => {
            state.loading = false
            state.users = action.payload
            state.error = ''
        })
        builder.addCase(fetchGeneral.rejected, (state, action) => {
            state.loading = false
            state.users = []
            state.error = action.error.message
        })
    }
})

export default generalSlice.reducer
