import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    cmpId: 0,
    companyName: '',
}

export const compSlice = createSlice({
    name: 'comp',
    initialState,
    reducers: {
        update: (state, action) =>{
            state.cmpId = action.payload.cmpId
            state.companyName = action.payload.companyName
        }
    }
})

export const { update } = compSlice.actions

export default compSlice.reducer