const menuItems = {
  items: [
    {
      id: 'navigation',
      title: 'SETUP',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          icon: 'feather icon-home',
          url: '/app/dashboard/analytics',
          breadcrumbs: false
        },
        {
          id: 'companies',
          title: 'Companies',
          type: 'item',
          icon: 'feather icon-check-square',
          url: '/quote/comp-dash',
          breadcrumbs: false

        },
        {
          id: 'LOGOUT',
          title: 'LogOut',
          type: 'item',
          icon: 'feather icon-log-out',
          url: '/auth/signout',
          breadcrumbs: false
        },
      ]
    },
    // {
    //   id: 'process',
    //   title: 'PROCESS',
    //   type: 'group',
    //   icon: 'icon-support',
    //   children: [
    //     {
    //       id: 'quote',
    //       title: 'Quotes',
    //       type: 'item',
    //       icon: 'feather icon-file-text',
    //       url: '/quote/quote-dash',
    //       breadcrumbs: false

    //     },
    //     {
    //       id: 'invoice',
    //       title: 'Invoices',
    //       type: 'item',
    //       url: '/sample-page',
    //       classes: 'nav-item',
    //       icon: 'feather icon-sidebar'
    //     },
    //   ]
    // }
  ]
};

export default menuItems;
