import { createSlice, createAsyncThunk  } from '@reduxjs/toolkit';
import UserService from "../../services/user-service";

const initialState = {
    loading: false,
    users: [],
    error: ''
}

export const fetchEmployees = createAsyncThunk('customer/fetchEmployees', async () => {
    const response = await UserService.getEmployees();
    return response.data;
})

const customerSlice = createSlice({
    name: 'customer',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchEmployees.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchEmployees.fulfilled, (state, action) => {
            state.loading = false
            state.users = action.payload
            state.error = ''
        })
        builder.addCase(fetchEmployees.rejected, (state, action) => {
            state.loading = false
            state.users = []
            state.error = action.error.message
        })
    }
})

export default customerSlice.reducer